<template>
    <client-page>
        <v-container>
            <sub-visual subTabNone sh="개인정보처리방침" bg="/images/sub/visual/sv6.jpg"> </sub-visual>

            <section class="section">
                <v-container>
                    <terms-component code="privacy" />
                </v-container>
            </section>
        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import TermsComponent from "@/components/client/terms/terms-component.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        TermsComponent,
    },
    data() {
        return {
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style scoped>
</style>